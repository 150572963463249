const data = [
   {
      "Timestamp": "6/18/2024 23:17:58",
      "name": "Satvik Upadhyay ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/satvik-upadhyay-b1b858280?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/satvikk_7?igsh=ZmI4NHk3dmFzMzlv",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "https://x.com/satvik_twts?t=NbTqinyJntk6sYADnVaG7Q&s=09",
      "emailAddress": "stvklog01@gmail.com",
      "photo": "https://drive.google.com/open?id=1AVFBHG-sUpKGrA8Q2OJTzuM6gQKYKOVk"
   },
   {
      "Timestamp": "6/18/2024 23:19:09",
      "name": "Samriddhi Srivastava ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/samriddhi-srivastava-a73791290",
      "instagramProfile": "https://www.instagram.com/samriddhi5085/",
      "facebookProfile": "",
      "githubProfile": "https://github.com/Samriddhi10204",
      "xProfile": "",
      "emailAddress": "samriddhisrivastava10204@gmail.com",
      "photo": "https://drive.google.com/open?id=1xZ0oLs__5pa2KpaDgwYmTMnK9fDh0O5J"
   },
   {
      "Timestamp": "6/18/2024 23:20:44",
      "name": "Akshansh Kumawat ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/akshanshkumawat",
      "instagramProfile": "https://www.instagram.com/akshansh.kumawat",
      "facebookProfile": "https://www.facebook.com/Akshansh.Kumawat",
      "githubProfile": "https://github.com/akshanshkumawat",
      "xProfile": "https://x.com/AkshanshKumawa",
      "emailAddress": "kumawatakshansh@gmail.com",
      "photo": "https://drive.google.com/open?id=17asgx2z3jm7BwSoGNK4mqez_spI1m4FW"
   },
   {
      "Timestamp": "6/18/2024 23:38:22",
      "name": "Jayvardhan Bhardwaj ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/jayvardhan-bhardwaj-225040196?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/jaybharadwaj_27?igsh=MTEwM3J4OXpmeHlmcQ==",
      "facebookProfile": "",
      "githubProfile": "https://github.com/ALPHAtech27",
      "xProfile": "https://x.com/Jaybharadwaj_27?t=x0VL5-yFFG8-TkUn-whv4Q&s=09",
      "emailAddress": "jaybharadwaj2244@gmail.com",
      "photo": "https://drive.google.com/open?id=10DVrlcMbzOR2N560vyBpOClit9Mp4c8C"
   },
   {
      "Timestamp": "6/18/2024 23:43:01",
      "name": "Shaalin Chandra",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/shaalinchandra?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      "instagramProfile": "https://www.instagram.com/shaalin27_",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "shaalin27@gmail.com",
      "photo": "https://drive.google.com/open?id=10OzDPDVZQ-l7ztLyymZ_5_H2YIxa3Msa"
   },
   {
      "Timestamp": "6/18/2024 23:44:04",
      "name": "Tanushree Gupta ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/tanushree-gupta-0a7a2a237?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/tanugupta_1004?igsh=ajFpbmllb2JmZnVy",
      "facebookProfile": "",
      "githubProfile": "https://github.com/tg1004",
      "xProfile": "",
      "emailAddress": "tanugupta1004@gmail.com",
      "photo": "https://drive.google.com/open?id=1G-98ZXu0mW0hl3o3Qo27a1Nwr-jyp-Ws"
   },
   {
      "Timestamp": "6/18/2024 23:55:32",
      "name": "Chatrapal Singh ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/chatrapal-singh-189318210?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/chatrapal__/",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "https://x.com/ChatrapalLodhi3",
      "emailAddress": "chatrapallodhi01@gmail.com",
      "photo": "https://drive.google.com/open?id=1jtjiocG8D8OceYeOqZJCbvDv74VYe37p"
   },
   {
      "Timestamp": "6/19/2024 0:26:10",
      "name": "Aastha Pant",
      "year": "2nd",
      "vertical": "Designer ",
      "linkedinProfile": "https://www.linkedin.com/in/aastha-pant-16bb3b2a4?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/aastha.2101?igsh=cnY2MHdjNGk5djU2",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "aasthapant21@gmail.com",
      "photo": "https://drive.google.com/open?id=18mCUGjXggRDyfm9XIyoblQVQHHrCXQNZ"
   },
   {
      "Timestamp": "6/19/2024 0:36:31",
      "name": "Vedika Vishwakarma ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/vedika-vishwakarma-ab5542290",
      "instagramProfile": "https://www.instagram.com/__vedika.v__?igsh=MTMxbDU0MXQ3Y3k1NQ%3D%3D&utm_source=qr",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "vishwakarmavedika550@gmail.com",
      "photo": "https://drive.google.com/open?id=1U6-w8pAOoOoKMJKszrRJLmeRpuo-LRqm"
   },
   {
      "Timestamp": "6/19/2024 0:51:35",
      "name": "Farheen Ahmed",
      "year": "2nd",
      "vertical": "Executive",
      "linkedinProfile": "https://www.linkedin.com/in/farheen-ahmed-255b71286",
      "instagramProfile": "https://www.instagram.com/farheen_794?igsh=a2NqNXo1ZjBpMWs0",
      "facebookProfile": "",
      "githubProfile": "https://github.com/Farheen794",
      "xProfile": "",
      "emailAddress": "farheen.ahmed2004@gmail.com",
      "photo": "https://drive.google.com/open?id=1CM6KuTotOvo3Z502xOiZNG57V7945oJH"
   },
   {
      "Timestamp": "6/19/2024 1:01:26",
      "name": "Tanishq Dinkar",
      "year": "2nd",
      "vertical": "Video Editor",
      "linkedinProfile": "https://www.linkedin.com/in/tanishq-dinkar-0bb0b5290?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/tanishqdinkar19?igsh=OWszZGxvd3QwaXpw",
      "facebookProfile": "https://www.facebook.com/profile.php?id=100093239895303&mibextid=ZbWKwL",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "tanishqdinkar07@gmail.com",
      "photo": "https://drive.google.com/open?id=15xt8Md2G3s0Z530y1TIzXDGi2D683lih"
   },
   {
      "Timestamp": "6/19/2024 1:02:31",
      "name": "Jay Gautam",
      "year": "2nd",
      "vertical": "Executive",
      "linkedinProfile": "https://www.linkedin.com/in/jay-gautam-025989293",
      "instagramProfile": "https://www.instagram.com/_jaygautam_",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "jay7322849@gmail.com",
      "photo": "https://drive.google.com/open?id=1ZGRS7bPuPzNrX8muQrnl0UzFoJ_Grf12"
   },
   {
      "Timestamp": "6/19/2024 1:10:21",
      "name": "Kunal Chouhan ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/kunal-chouhan-72447a220?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      "instagramProfile": "https://www.instagram.com/kunal_chouhan_99?igsh=aTM0b250cXVnMjl1&utm_source=qr",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "kunalchouhan2004@gmail.com",
      "photo": "https://drive.google.com/open?id=1wzOUkUAfslh8jwBenLv9bzbc6znqpmL7"
   },
   {
      "Timestamp": "6/19/2024 3:05:24",
      "name": "Rayyan Zameer Baikadi ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://in.linkedin.com/in/rayyan-zameer-baikadi-585612251",
      "instagramProfile": "https://www.instagram.com/rz_intheflesh?igsh=MXJjaGJvYjVkc2M0Mw==",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "2311201370@stu.manit.ac.in",
      "photo": "https://drive.google.com/open?id=1pmHyeRsWRvfp8XOz_01uQDTcM3bT5Ztm"
   },
   {
      "Timestamp": "6/19/2024 4:38:20",
      "name": "Satyam Patel ",
      "year": "2nd",
      "vertical": "Video Editor ",
      "linkedinProfile": "https://www.linkedin.com/in/satyam-patel-599812290?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/_satyam.06?igsh=MTk1ZXhxeDhjZ2NxeQ==",
      "facebookProfile": "https://www.facebook.com/satyam.patel.3517563",
      "githubProfile": "",
      "xProfile": "https://x.com/Satyam_121_?t=TeCDWRCaBx3U9pcrhsnsKA&s=09",
      "emailAddress": "patelsatyam2706@gmail.com",
      "photo": "https://drive.google.com/open?id=1LFIosadBr85R12ciJ5up-rPMwCaUgMcF"
   },
   {
      "Timestamp": "6/19/2024 7:24:21",
      "name": "Yamini Upadhyay ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/yamini-upadhyay-856729298?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/yaa_mini11?igsh=MWMwNWV3dmt0N2ptNA==",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "yaminiupadhyay11@gmail.com",
      "photo": "https://drive.google.com/open?id=1HQrrMTORT9g4evcMnsWo8tNTgqkDfGmd"
   },
   {
      "Timestamp": "6/19/2024 8:39:29",
      "name": "Sandeep Sahu ",
      "year": "2nd",
      "vertical": "Photographer",
      "linkedinProfile": "https://www.linkedin.com/in/sandeep-sahu-1432ab22a",
      "instagramProfile": "https://www.instagram.com/ssandeepsahu19?igsh=czA2OThoMmx4Zmxt",
      "facebookProfile": "https://www.facebook.com/ashish.sahu.391420",
      "githubProfile": "https://github.com/ssandeepsahu19",
      "xProfile": "https://x.com/sandipsahu1512?t=4SD_SJtMF8MZc_aoixqLrg&s=08",
      "emailAddress": "sandeepsahu38963@gmail.com",
      "photo": "https://drive.google.com/open?id=10kvPDMNv5iSykomJGWI91-T0rpYAvyzh"
   },
   {
      "Timestamp": "6/19/2024 10:18:35",
      "name": "Neha Jhariya ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/neha-jhariya-119521290?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/neha.jharia?igsh=eTN1ODBjeWdhaHZp",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "itsnehajhariya59@gmail.com",
      "photo": "https://drive.google.com/open?id=1qIRh_e-N4gQRDcbJqrO2IyGuogQWKync"
   },
   {
      "Timestamp": "6/19/2024 10:23:31",
      "name": "Aditya sen ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/aditya-sen-9955a4236?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/adityaaaasen?igsh=MWQwb3M5YXR0a3ExNA==",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "adityasen2491@gmail.com",
      "photo": "https://drive.google.com/open?id=1C7tK4cPBFM0MDsKz-Bp7Xda5OARWmOoo"
   },
   {
      "Timestamp": "6/19/2024 10:39:29",
      "name": "Monu Kumawat",
      "year": "2nd",
      "vertical": "Executive",
      "linkedinProfile": "https://www.linkedin.com/in/monu-kumawat-4a5602295",
      "instagramProfile": "https://www.instagram.com/monu.kumawat.1412?igsh=aTN1M2M3YmgycXMz",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "monukumwat14@gmail.com",
      "photo": "https://drive.google.com/open?id=1-PB-L1qzf9Ow0zNOHn9zudcprgssu7pu"
   },
   {
      "Timestamp": "6/19/2024 10:49:27",
      "name": "Ansh Jalan",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/anshjalan",
      "instagramProfile": "https://www.instagram.com/anshjalan_",
      "facebookProfile": "",
      "githubProfile": "https://github.com/anshjalan",
      "xProfile": "https://x.com/anshjalan_",
      "emailAddress": "jalanansh25@gmail.com",
      "photo": "https://drive.google.com/open?id=1eqVVX3pQx-AXhKU1gS9jKiIyTAjIZah9"
   },
   {
      "Timestamp": "6/19/2024 10:50:20",
      "name": "Pragati Baderiya ",
      "year": "2nd",
      "vertical": "Executive",
      "linkedinProfile": "https://www.linkedin.com/in/pragati-baderiya-663974281?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/pragatibaderiya?igsh=bGZ3dGttZTFraWkx",
      "facebookProfile": "https://www.facebook.com/profile.php?id=61555126134706",
      "githubProfile": "https://github.com/PragatiBaderiya?tab=overview&from=2024-05-01&to=2024-05-31",
      "xProfile": "",
      "emailAddress": "pragatibaderiya12@gmail.com",
      "photo": "https://drive.google.com/open?id=14CnjbXDu1IQEP2y4_WXG_eCiLi8D469m"
   },
   {
      "Timestamp": "6/19/2024 11:18:03",
      "name": "Alina malik",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/alina-malik-439a342a7",
      "instagramProfile": "https://www.instagram.com/alina_malik7.8?igsh=aXRpanh6ZGExZThk",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "ak7417260@gmail.com",
      "photo": "https://drive.google.com/open?id=11proOuxEugLQUGn8zamByukcDzeojPDM"
   },
   {
      "Timestamp": "6/19/2024 11:51:13",
      "name": "Manjari Gaikwad",
      "year": "2nd",
      "vertical": "Executive",
      "linkedinProfile": "https://www.linkedin.com/in/manjari-gaikwad-823307286/",
      "instagramProfile": "https://www.instagram.com/manjari.gaikwad?igsh=aWE4MmphbW5mdm9s",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "https://x.com/simplymanjari",
      "emailAddress": "manjarigaikwad5@gmail.com",
      "photo": "https://drive.google.com/open?id=1z78nVDVsSHGGWQVtLGaWBYAhu2lFS7Sr"
   },
   {
      "Timestamp": "6/19/2024 12:09:51",
      "name": "Nishi Singhal ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/nishi-singhal-12482a290?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/invites/contact/?igsh=1g5fx5dik0s04&utm_content=pdpor0m",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "nishisinghal989@gmail.com",
      "photo": "https://drive.google.com/open?id=14EoB9oShwAEsTdH5ALj9cNp0MK9QsViI"
   },
   {
      "Timestamp": "6/19/2024 12:16:28",
      "name": "Deekshita Mathur",
      "year": "2nd",
      "vertical": "Executive",
      "linkedinProfile": "https://www.linkedin.com/in/deekshita-mathur-072813290",
      "instagramProfile": "https://www.instagram.com/deekshita_mathur?igsh=MWd6ZDZ2dHl5OG1mMQ==",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "deekshitamathur2507@gmail.com",
      "photo": "https://drive.google.com/open?id=1ZRmC4KXVeovSKVcomo_-nhjjSIia58uy"
   },
   {
      "Timestamp": "6/19/2024 12:21:35",
      "name": "Vivek Kumar Taldi",
      "year": "2nd",
      "vertical": "Executive",
      "linkedinProfile": "https://www.linkedin.com/in/vivek-kumar-taldi-a130a8290/",
      "instagramProfile": "https://www.instagram.com/vivekt.in4k?igsh=MXg1MGd5MjQ1bTk4Nw==",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "https://x.com/Imvivkt",
      "emailAddress": "taldivk_05@yahoo.com",
      "photo": "https://drive.google.com/open?id=14l5bW5ydB7DCpmIY_OlzLpsfvqb7gSsj"
   },
   {
      "Timestamp": "6/19/2024 13:04:50",
      "name": "Shubh Gupta ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "www.linkedin.com/in/shubhgupta16",
      "instagramProfile": "https://www.instagram.com/shubhgupta_1609?igsh=MTVrZDV1cjk1MGhqbg==",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "shubh.g200416@gmail.com",
      "photo": "https://drive.google.com/open?id=1bS7KbZu5ciguz3iRRhh2xzu-SeKvXhE8"
   },
   {
      "Timestamp": "6/19/2024 13:27:10",
      "name": "Rohan Kumar Sahu",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/rohan-kumar-sahu-78179310b",
      "instagramProfile": "https://www.instagram.com/_.rohan.s_x_x_?igsh=MXdva2RpbXFxNzRsOA==",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "rohansahu733@gmail.com",
      "photo": "https://drive.google.com/open?id=1UKjzLmNLNYSYZ3NVayzm-NHvLiF70oTA"
   },
   {
      "Timestamp": "6/19/2024 13:31:47",
      "name": "Ganga Tiwari ",
      "year": "2nd",
      "vertical": "Executive",
      "linkedinProfile": "linkedin.com/in/ganga-tiwari-638aa1290",
      "instagramProfile": "https://www.instagram.com/_ganga_tiwari_?igsh=czNhaHplczM4MW84",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "tiwariganga0208@gmail.com",
      "photo": "https://drive.google.com/open?id=1rynT5n1AyHkA2tozmtX3HwQXDUNqBIvE"
   },
   {
      "Timestamp": "6/19/2024 13:36:17",
      "name": "Shreya Dwivedi",
      "year": "2nd",
      "vertical": "Designer",
      "linkedinProfile": "https://www.linkedin.com/in/shreya-dwivedi-72b715298?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/shreya_dwivediii24?igsh=MWhzdDB2bm05Ym9oeQ==",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "shreyaashvani200@gmail.com",
      "photo": "https://drive.google.com/open?id=1lriaS_8jEkU6JUq-QfyIdOOhnrdQzq6L"
   },
   {
      "Timestamp": "6/19/2024 13:38:42",
      "name": "Sanjana Malani",
      "year": "2nd",
      "vertical": "Executive",
      "linkedinProfile": "https://www.linkedin.com/feed/?trk=guest_homepage-basic_google-one-tap-submit",
      "instagramProfile": "https://www.instagram.com/sanjana_1003_?igsh=MXh0cDd5eG91c3AzdA%3D%3D&utm_source=qr",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "https://x.com/sanjana_malani?s=21",
      "emailAddress": "sanjanam0306@gmail.com",
      "photo": "https://drive.google.com/open?id=1_dj-6RWFO7O8xmiZxolwLb7_p8jcSGD8"
   },
   {
      "Timestamp": "6/19/2024 13:39:45",
      "name": "Shivam kumar ",
      "year": "2nd",
      "vertical": "Designer ",
      "linkedinProfile": "https://www.linkedin.com/in/shivam-kumar-a957a4290?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/shivam7_07?igsh=MXUzZHMxcHVydzQxNg==",
      "facebookProfile": "",
      "githubProfile": "https://github.com/Shivam-k27",
      "xProfile": "https://x.com/ShivamKumar3326?t=AldNW0zIy2aHbx793FqZAQ&s=09",
      "emailAddress": "shivamnitb26@gmail.com",
      "photo": "https://drive.google.com/open?id=13hESuZhdi00kxd3EVQBgJEakkIy4_g3w"
   },
   {
      "Timestamp": "6/19/2024 13:55:46",
      "name": "Manvika agarwal",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "http://linkedin.com/in/manvika-agarwal-16212b291",
      "instagramProfile": "https://www.instagram.com/manvika.__?igsh=MWVzdWx3dzZkMmpoNQ%3D%3D&utm_source=qr",
      "facebookProfile": "https://www.facebook.com/manvika.agrawal.98",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "manvikaagarwal23@gmail.com",
      "photo": "https://drive.google.com/open?id=1uOoLHZYbpl2UZo-kQaO5H1JOGjoDEfjH"
   },
   {
      "Timestamp": "6/19/2024 14:25:40",
      "name": "Disha Bhadauria ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/disha-bhadauria-328214301?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/disha_bhadauria?igsh=b2U5eXJqcWJsZDAx",
      "facebookProfile": "https://www.facebook.com/profile.php?id=61550841730820&mibextid=ZbWKwL",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "dishabhadauria85@gmail.com",
      "photo": "https://drive.google.com/open?id=1udbwrEN3f8ZSjUaJgjAMGUUxqnxW1YBF"
   },
   {
      "Timestamp": "6/19/2024 14:39:38",
      "name": "Jyotika singh",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/jyotika-singh-275541290?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/jyoti__ka1511?igsh=MXJuNHJ2bHI3cTl0ZQ==",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "josinkgh11@gmail.com",
      "photo": "https://drive.google.com/open?id=1lmXXhuVMdsobUge8xygRe4A4oeoaomrD"
   },
   {
      "Timestamp": "6/19/2024 15:57:17",
      "name": "Anubhav Nirwan",
      "year": "2nd",
      "vertical": "Web Developer ",
      "linkedinProfile": "https://www.linkedin.com/in/anubhav-nirwan-7a89ab275",
      "instagramProfile": "https://www.instagram.com/anubhav__nirwan/",
      "facebookProfile": "",
      "githubProfile": "https://github.com/nirwan-teji",
      "xProfile": "",
      "emailAddress": "nirwana12anubhav@gmail.com",
      "photo": "https://drive.google.com/open?id=1TFj-0E_bM-YyJtnEMC4vUQ46RqUTfMqO"
   },
   {
      "Timestamp": "6/19/2024 16:24:40",
      "name": "Shreya Agrawal ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/shreya-agrawal-703796290?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/_shreyagrwl?igsh=b292N3B6ZTFpcXBs",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "shreyaagrwl18@gmail.com",
      "photo": "https://drive.google.com/open?id=19IJXQILkNiPdUxLgV322zWirjTuMHwP3"
   },
   {
      "Timestamp": "6/19/2024 17:52:53",
      "name": "Basu Yadav",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/basu-yadav-37615b289",
      "instagramProfile": "https://www.instagram.com/basuyadav_14?igsh=ZHd5bDBla2F5MnMw",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "basuyadav1411@gmail.com",
      "photo": "https://drive.google.com/open?id=1fAIdmwL1iOcW7jBuO2TZjFIrnjXv2KHT"
   },
   {
      "Timestamp": "6/19/2024 17:56:49",
      "name": "Adnan Ahmad Abbasi",
      "year": "2nd",
      "vertical": "Photographer ",
      "linkedinProfile": "https://www.linkedin.com/in/adnan-ahmad-abbasi-0698b2290?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/caffeinated_adnan?igsh=NzB0MDBkNjlxcHpk",
      "facebookProfile": "https://www.facebook.com/profile.php?id=100027640181977",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "aaabbasi.2005@gmail.com",
      "photo": "https://drive.google.com/open?id=19MqD2_7kUWsZttCk716pNr8XuiSFIJvi"
   },
   {
      "Timestamp": "6/19/2024 18:44:34",
      "name": "Pooja Shinde",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/pooja-shinde-45236a291/",
      "instagramProfile": "https://www.instagram.com/thepoojashinde/",
      "facebookProfile": "",
      "githubProfile": "https://github.com/pooja-kun",
      "xProfile": "",
      "emailAddress": "poojashindeps213@gmail.com",
      "photo": "https://drive.google.com/open?id=1v9cskIJzIQgtnhOUM7JSjkUS8YeQnlwt"
   },
   {
      "Timestamp": "6/19/2024 18:56:50",
      "name": "Prakhar Mishra",
      "year": "2nd",
      "vertical": "Web Developer ",
      "linkedinProfile": "https://www.linkedin.com/in/prakhar-mishra-553638271?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/pra.khxr?igsh=MThpOWM3dG1iNXppNQ==",
      "facebookProfile": "",
      "githubProfile": "https://github.com/pm-8",
      "xProfile": "",
      "emailAddress": "mishraprakhar150@gmail.com",
      "photo": "https://drive.google.com/open?id=1zpCAgDvrKJ7yDDwqvbwqw8XvTXTGZYqf"
   },
   {
      "Timestamp": "6/19/2024 21:39:48",
      "name": "Mayank Tripathi ",
      "year": "2nd",
      "vertical": "Executive",
      "linkedinProfile": "https://www.linkedin.com/in/mayank-tripathi-8301b0294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/__mmayank?igsh=ZmVhbmYwZWR3MHNv",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "sanojsus2528@gmail.com",
      "photo": "https://drive.google.com/open?id=1ceocAiBrkeh5rkfd0kgIfWCsBe4HCLfC"
   },
   {
      "Timestamp": "6/19/2024 23:00:11",
      "name": "Vaibhav Kumar Sinha ",
      "year": "2nd",
      "vertical": "Executive",
      "linkedinProfile": "https://www.linkedin.com/in/vaibhav-kumar-sinha-070795311",
      "instagramProfile": "https://www.instagram.com/vaibhavx111",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "vaibhavkrsinha123@gmail.com",
      "photo": "https://drive.google.com/open?id=19Q-Ut2rpTPYPFLyfoLIk9ITiBuekUR-V"
   },
   {
      "Timestamp": "6/20/2024 0:46:04",
      "name": "Devansh Soni",
      "year": "2nd",
      "vertical": "Executive",
      "linkedinProfile": "https://www.linkedin.com/in/devansh-soni-067629290/",
      "instagramProfile": "",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "soni.devansh9621@gmail.com",
      "photo": "https://drive.google.com/open?id=1wBEPqXD6fEjznPDMo9RJU3F5MRlZOHsk"
   },
   {
      "Timestamp": "6/20/2024 11:23:27",
      "name": "Aman Parte",
      "year": "2nd",
      "vertical": "Video Editor",
      "linkedinProfile": "https://www.linkedin.com/in/aman-parte-033984289",
      "instagramProfile": "https://www.instagram.com/_.aman.parte._/",
      "facebookProfile": "https://www.facebook.com/profile.php?id=61554641415218&mibextid=ZbWKwL",
      "githubProfile": "https://github.com/AmanParte10",
      "xProfile": "",
      "emailAddress": "parteamanxyz@gmail.com",
      "photo": "https://drive.google.com/open?id=1qflpVG5RGo5VzJGS6huDu2hDMrWaqvnV"
   },
   {
      "Timestamp": "6/20/2024 14:12:11",
      "name": "Neev Sahu",
      "year": "2nd",
      "vertical": "Photographer ",
      "linkedinProfile": "https://www.linkedin.com/in/neev-sahu-6a08b028b",
      "instagramProfile": "https://www.instagram.com/neevsahu__?igsh=MWhzNHJpcHcxOW5oMA==",
      "facebookProfile": "https://www.facebook.com/neev.sahu?mibextid=rS40aB7S9Ucbxw6v",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "neev.sahu69@gmail.com",
      "photo": "https://drive.google.com/open?id=1QHkQyZpCdpXPtbTCCeY17eU9PLOwDV-Z"
   },
   {
      "Timestamp": "6/20/2024 17:49:52",
      "name": "Rupal Choudhary ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/rupal-choudhary-67a227217?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      "instagramProfile": "https://www.instagram.com/quadrupal_?igsh=MXVlc2VvMmJpNjVjcA%3D%3D&utm_source=qr",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "rudrakant2004@gmail.com",
      "photo": "https://drive.google.com/open?id=1-HDRLbtLdgzGtEH_bv9oNqVWJIP2E-jo"
   },
   {
      "Timestamp": "6/20/2024 22:47:27",
      "name": "Diyashi Roy",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/diyashi-roy-894811290?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      "instagramProfile": "https://www.instagram.com/diiyashii?igsh=ZHF3d2htZmVoNjVu",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "diyashiroy04@gmail.com",
      "photo": "https://drive.google.com/open?id=12J2Hm8yOV012DH8xS4yk1WbM1PctxbtC"
   },
   {
      "Timestamp": "6/21/2024 17:33:40",
      "name": "Taniksha Singh",
      "year": "2nd",
      "vertical": "Designer",
      "linkedinProfile": "https://www.linkedin.com/in/taniksha-singh-5312a0311?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/tanik.sha_singh?igsh=MXI1M3VmbDRncThrZw==",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "tanikshasingh2006@gmail.com",
      "photo": "https://drive.google.com/open?id=1sAkRZ873uoqnudhonYxB0svDcoBMVLR0"
   },
   {
      "Timestamp": "6/21/2024 18:10:01",
      "name": "Jeetendra Sahu ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "",
      "instagramProfile": "https://www.instagram.com/iamjeet707?igsh=cXMwMHkxdGp3Z2kx",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "jeetendrasahu19167259@gmail.com",
      "photo": ""
   },
   {
      "Timestamp": "6/21/2024 19:24:27",
      "name": "Kratika Rimza ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/kratika-rimza-85835b283?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      "instagramProfile": "https://www.instagram.com/kratika_rimza?igsh=MTBjemszMHkxaml0dQ==",
      "facebookProfile": "https://www.facebook.com/kratika.rimza.3",
      "githubProfile": "",
      "xProfile": "",
      "emailAddress": "kratika.rimza@gmail.com",
      "photo": "https://drive.google.com/open?id=1GmeSHh4pMm-X28IsQbJGhM2jrdlHuBA1"
   },
   {
      "Timestamp": "6/21/2024 20:04:58",
      "name": "Piyush sharma",
      "year": "2nd",
      "vertical": "Designer",
      "linkedinProfile": "https://www.linkedin.com/in/piyush-sharma-0908a5290/",
      "instagramProfile": "https://www.instagram.com/piuush__?igsh=MTY5YnJqZXkxOGhpcw==",
      "facebookProfile": "https://www.facebook.com/profile.php?id=100087756890423&mibextid=ZbWKwL",
      "githubProfile": "https://github.com/piyush271005",
      "xProfile": "https://x.com/Piuush__",
      "emailAddress": "piyushsharmarise2710@gmail.com",
      "photo": "https://drive.google.com/open?id=1vCcGK7xlwHNOAIyCw94BY2am7BO6-vSw"
   },
   {
      "Timestamp": "6/21/2024 22:37:33",
      "name": "Vedant Birla ",
      "year": "2nd",
      "vertical": "Executive ",
      "linkedinProfile": "https://www.linkedin.com/in/vedant-birla-4b2407290",
      "instagramProfile": "https://www.instagram.com/vedantbirl_a?igsh=ZjRkanBoczV3eGtl",
      "facebookProfile": "",
      "githubProfile": "",
      "xProfile": "https://x.com/vedant_bir83807?t=kvms7DLKndwGS2dYWF0GAA&s=08",
      "emailAddress": "birlavedant9012006@gmail.com",
      "photo": "https://drive.google.com/open?id=1_VmkUvGOQWkY06_k8jOjSVIMyKmrg1BK"
   },
   {
      "Timestamp": "6/24/2024 9:21:01",
      "name": "Mohit Singh",
      "year": "2nd",
      "vertical": "Web Developer",
      "linkedinProfile": "https://www.linkedin.com/in/mohhit/",
      "instagramProfile": "https://www.instagram.com/mohhit_24?igsh=MTk4eXM3YWN0OTd4MA==",
      "facebookProfile": "",
      "githubProfile": "https://github.com/Mohhit6075/",
      "xProfile": "",
      "emailAddress": "mohitsingh6266@gmail.com",
      "photo": "https://drive.google.com/open?id=1RxptVCgjEFLCzUGylBPjzEWgn-66fxXa"
   },
   {
      "timestamp": "6/15/2023 23:12:43",
      "emailAddress": "analpatil07@gmail.com",
      "name": "Aanal Patil",
      "year": "3rd",
      "vertical": "Head - Quizzing",
      "linkedinProfile": "https://www.linkedin.com/in/aanal-patil-1ab6861b3",
      "instagramProfile": "instagram.com/aanalpatil24",
      "twitterProfile": "https://twitter.com/aanalpatil24?t=O3oe5cBR2OKDYtT8XrfA1g&s=08",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1tTN06bslU1HXcIzYEeU2L0VNKvXrkmMv"
   },
   {
      "timestamp": "6/16/2023 18:57:35",
      "emailAddress": "abhavya.dubey1@gmail.com",
      "name": "Abhavya Dubey",
      "year": "3rd",
      "vertical": "Head - Content",
      "linkedinProfile": "https://www.linkedin.com/in/abhavya-dubey/",
      "instagramProfile": "https://www.instagram.com/abhavyadubey1904/",
      "twitterProfile": "https://twitter.com/Dubey1Abhavya",
      "githubProfile": "https://github.com/abhavyaD19",
      "photo": "https://drive.google.com/open?id=1t--yZIWJk4C696NGOoWWdIEuYEupgEO7"
   },
   {
      "timestamp": "6/16/2023 16:11:22",
      "emailAddress": "rabhinav839@gmail.com",
      "name": "Abhinav Rai",
      "year": "3rd",
      "vertical": "Students Jt. General Secretary(Administration)",
      "linkedinProfile": "https://www.linkedin.com/in/abhinav-rai-7ba49b225",
      "instagramProfile": "https://instagram.com/abhinav.rai.22?igshid=ZDc4ODBmNjlmNQ==",
      "twitterProfile": "https://twitter.com/Abhinavrai22?t=4oqyiqjQV8DSMVe54lPPKQ&s=08",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1BTWjXZ_p4gvKs2AePIRjXFxMlCbgVOd0"
   },
   {
      "timestamp": "6/16/2023 15:58:49",
      "emailAddress": "adit.dashore2004@gmail.com",
      "name": "Adit Dashore",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/adit-dashore-08483b27a",
      "instagramProfile": "https://instagram.com/_.adit_dashore?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1mqMBa_NXiCYzUiHSvrZgO59q3n3q6gQ-"
   },
   {
      "timestamp": "6/16/2023 16:30:26",
      "emailAddress": "singhyadav.vinodkumar@gmail.com",
      "name": "Aditya Krishna ",
      "year": "3rd",
      "vertical": "Head - Multimedia",
      "linkedinProfile": "",
      "instagramProfile": "https://instagram.com/adityakrishna469?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1DCmEWeWOjZV5mdqNOlEI5TcooF8oiHV9"
   },
   {
      "timestamp": "6/16/2023 20:50:35",
      "emailAddress": "patidarvasu22@gmail.com",
      "name": "Aditya Patidar ",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/aditya-patidar-4aaaa1257",
      "instagramProfile": "https://instagram.com/adityapatidar__?igshid=ZDc4ODBmNjlmNQ==",
      "twitterProfile": "https://twitter.com/Adityapati04?t=BkyjJUvJtGMuIsj3joLCVw&s=09",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1zdiH6RIY4Ia4mBbZV6kM5hNpJLIn76MJ"
   },
   {
      "timestamp": "6/16/2023 1:21:05",
      "emailAddress": "akshayamarpu11@gmail.com",
      "name": "Akshaya Marpu",
      "year": "3rd",
      "vertical": "Head - Media and Publicity",
      "linkedinProfile": "https://www.linkedin.com/in/akshaya-marpu-61764827a",
      "instagramProfile": "https://instagram.com/akhi_793?igshid=NTc4MTIwNjQ2YQ==",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1Pn0jxAvxeDFoL3ROvrqw1RGy17OoRjQP"
   },
   {
      "timestamp": "6/16/2023 0:54:07",
      "emailAddress": "akshitakumar2025@gmail.com",
      "name": "Akshita Kumar",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/akshita-kumar-a1637b269",
      "instagramProfile": "https://instagram.com/hey.akshita?igshid=OGQ5ZDc2ODk2ZA==",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1Z9P4NwCw0EflGV4nTAt4m0FITEoX92Xe"
   },
   {
      "timestamp": "6/16/2023 21:35:31",
      "emailAddress": "ankitchoudharyqwert@gmail.com",
      "name": "Ankit Choudhary ",
      "year": "3rd",
      "vertical": "Head - Web Operations",
      "linkedinProfile": "https://www.linkedin.com/in/ankit-choudhary-62bb87256",
      "instagramProfile": "https://instagram.com/ankittchoudhary?igshid=OGQ5ZDc2ODk2ZA==",
      "twitterProfile": "https://twitter.com/ChAnkit679",
      "githubProfile": "https://github.com/ch-ankit679",
      "photo": "https://drive.google.com/open?id=10wKWOBZKaMTHFHlYon1suOGPt7l2xiG9"
   },
   {
      "timestamp": "6/16/2023 0:46:31",
      "emailAddress": "anujk.2304@gmail.com",
      "name": "Anuj Khandelwal",
      "year": "3rd",
      "vertical": "Head - Finance",
      "linkedinProfile": "https://www.linkedin.com/in/anuj-khandelwal-a03a56175",
      "instagramProfile": "https://instagram.com/anuj_khandelwal23?igshid=NGExMmI2YTkyZg==",
      "twitterProfile": "https://twitter.com/anujk2304",
      "githubProfile": "https://github.com/anuj-23",
      "photo": "https://drive.google.com/open?id=1BGNBkLO7U9nYiWr59reCPsaTs-F9asa8"
   },
   {
      "timestamp": "6/16/2023 17:24:58",
      "emailAddress": "kumarjaychaitanya@gmail.com",
      "name": "Chaitanya Bhargava ",
      "year": "4th",
      "vertical": "Students Facilitator",
      "linkedinProfile": "https://www.linkedin.com/in/chaitanya-bhargava-0302631b2",
      "instagramProfile": "@wanderer_chaitanya ",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=195rHb4zugRC6zwQjnRxhohzdOqV9RkaH"
   },
   {
      "timestamp": "6/16/2023 1:09:03",
      "emailAddress": "borbanayush09@gmail.com",
      "name": "Dhananjay Borban ",
      "year": "3rd",
      "vertical": "Head - Sponsorship and Marketing",
      "linkedinProfile": "https://www.linkedin.com/in/dhananjay-borban-a18515250",
      "instagramProfile": "https://instagram.com/dhananjayborban?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "https://twitter.com/DhananjayBorban?t=czQpCSFYP2lewegn3qTfnw&s=09",
      "githubProfile": "https://github.com/VviratT",
      "photo": "https://drive.google.com/open?id=1lu29-rzZD6wtde_0gsFezAq6UefSEX1t"
   },
   {
      "timestamp": "6/16/2023 17:00:05",
      "emailAddress": "gaurav901705@gmail.com",
      "name": "Gaurav Gupta",
      "year": "3rd",
      "vertical": "Head - Team Management",
      "linkedinProfile": "https://www.linkedin.com/in/gaurav-gupta-1ab9a8250",
      "instagramProfile": "https://instagram.com/gauravg_138?igshid=ZDc4ODBmNjlmNQ==",
      "twitterProfile": "https://twitter.com/gaurav901705",
      "githubProfile": "https://github.com/GauravGupta138",
      "photo": "https://drive.google.com/open?id=1UgwfGE7IZVJrY5Eg3g2zdY9Si_0iJjCG"
   },
   {
      "timestamp": "6/16/2023 9:25:19",
      "emailAddress": "agrawalhardik534@gmail.com",
      "name": "Hardik Agrawal",
      "year": "3rd",
      "vertical": "Heads-Logistics and Operations",
      "linkedinProfile": "https://www.linkedin.com/in/hardik-agrawal-500265251",
      "instagramProfile": "https://instagram.com/agrawal_hardik_534?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1RPjVmewSD2Af_taOlCD1WurlPIyzagea"
   },
   {
      "timestamp": "6/16/2023 0:35:59",
      "emailAddress": "harshitsingh789123@gmail.com",
      "name": "Harshit Singh",
      "year": "3rd",
      "vertical": "Heads-Media and Publicity",
      "linkedinProfile": "https://www.linkedin.com/in/harshit-singh-a7a360276",
      "instagramProfile": "https://instagram.com/_harshit_singh_07_?igshid=ZGUzMzM3NWJiOQ==",
      "twitterProfile": "",
      "githubProfile": "https://github.com/harshitsingh4321",
      "photo": "https://drive.google.com/open?id=1HKWC8vORwr_WSb-P4djMa_856Z5E6xYX"
   },
   {
      "timestamp": "6/16/2023 16:27:27",
      "emailAddress": "harshitsoni341@gmail.com",
      "name": "Harshit soni",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/harshit-soni-035307215",
      "instagramProfile": "https://instagram.com/harshit_soni_official?igshid=MzRlODBiNWFlZA==",
      "twitterProfile": "https://twitter.com/Harshit0120?t=MH-x84A9TVJMYDDOBWwSUw&s=08",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1AjoFGYTJsLxc9_WZ3gwlIFuNhzmqP_nt"
   },
   {
      "timestamp": "6/15/2023 23:54:36",
      "emailAddress": "jatinbaiswar08@gmail.com",
      "name": "Jatin Baiswar",
      "year": "3rd",
      "vertical": "Head - Design",
      "linkedinProfile": "https://www.linkedin.com/in/jatin-baiswar-4b9b98252",
      "instagramProfile": "https://instagram.com/iamthehellyouknow?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1SSGxSE53usD8Nzp_zmdI-0ShitSwcR4M"
   },
   {
      "timestamp": "6/16/2023 17:05:38",
      "emailAddress": "kalpitgnagar@gmail.com",
      "name": "Kalpit Nagar",
      "year": "3rd",
      "vertical": "Head - Creative",
      "linkedinProfile": "https://www.linkedin.com/in/kalpit-nagar-563166241",
      "instagramProfile": "https://instagram.com/_kalpitnagar?igshid=ZDc4ODBmNjlmNQ==",
      "twitterProfile": "https://twitter.com/nagar_kalpit?t=z6KWC7eng_SKBH5b-we1Aw&s=09",
      "githubProfile": "https://github.com/kalpitgnagar",
      "photo": "https://drive.google.com/open?id=13fDxj3HMe_PGcvKYXlEOtAhIvJj_DRGM"
   },
   {
      "timestamp": "6/16/2023 10:24:38",
      "emailAddress": "karankute05@gmail.com",
      "name": "Karan Kute ",
      "year": "3rd",
      "vertical": "Head - Technical",
      "linkedinProfile": "https://www.linkedin.com/in/karan-kute-26037b198",
      "instagramProfile": "https://instagram.com/karankute05?igshid=NGExMmI2YTkyZg==",
      "twitterProfile": "https://twitter.com/karan_kute05?t=QhvKnDWqzpYD5jgPUhK87g&s=09",
      "githubProfile": "https://github.com/kk1701",
      "photo": "https://drive.google.com/open?id=1sLl5kApa3qxjY8sjK-ebhGmbLg7Q_Zr0"
   },
   {
      "timestamp": "6/15/2023 23:32:18",
      "emailAddress": "29kartik2004@gmail.com",
      "name": "Kartik Gupta ",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "",
      "instagramProfile": "https://instagram.com/kartik.gupta7?igshid=NGExMmI2YTkyZg==",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=13M2wVy9J5NUhOeC-JfJqhPqP-TJL42eJ"
   },
   {
      "timestamp": "6/16/2023 15:58:44",
      "emailAddress": "shahkartik369@gmail.com",
      "name": "Kartik shah",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/kartik-shah-59ba47256",
      "instagramProfile": "https://instagram.com/kartikk_shah_?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "https://twitter.com/Kartik66990",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=19YWfsiSuROsx9cCM7MPDw3HheyN9aehX"
   },
   {
      "timestamp": "6/16/2023 15:57:55",
      "emailAddress": "kartikeykush5020909@gmail.com",
      "name": "Kartikey Kush Maurya ",
      "year": "3rd",
      "vertical": "Head - Creative",
      "linkedinProfile": "https://www.linkedin.com/in/kartikey-kush-maurya-715047251",
      "instagramProfile": "https://instagram.com/kartikey_kush_maurya?igshid=NGExMmI2YTkyZg==",
      "twitterProfile": "https://twitter.com/kush_kartikey?t=lYE0FXNeoiai9DSPUJRbUA&s=09",
      "githubProfile": "https://github.com/KartikeyKushwaha",
      "photo": "https://drive.google.com/open?id=1rY1hdRDO1dnqk5kg7Ykofha5lUYYIuZA"
   },
   {
      "timestamp": "6/16/2023 14:43:39",
      "emailAddress": "mansiupadhyay2004@gmail.com",
      "name": "Mansi Upadhyay ",
      "year": "3rd",
      "vertical": "Head - General Affairs",
      "linkedinProfile": "Mansi Upadhyay ",
      "instagramProfile": "__mansi_upadhyay",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1AcxWQO286wguDbq7Q_JeFtSXUi763rrk"
   },
   {
      "timestamp": "6/16/2023 16:03:19",
      "emailAddress": "palakagrawal0508@gmail.com",
      "name": "Palak Agrawal ",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/palak-agrawal-86962b27a",
      "instagramProfile": "https://instagram.com/___palak099__?igshid=NGExMmI2YTkyZg==",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1iSoLXk8QmHzA9TjtSdDqGKjnRuDOcasY"
   },
   {
      "timestamp": "6/16/2023 2:28:59",
      "emailAddress": "sonipalkesh43@gmail.com",
      "name": "Palkesh Soni ",
      "year": "3rd",
      "vertical": "Head - Design",
      "linkedinProfile": "https://www.linkedin.com/in/palkesh-soni-7b9b7a256",
      "instagramProfile": "https://instagram.com/palkesh.soni?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "https://twitter.com/palkesh_soni6?t=wdvzQza7rmJCUyqX7bU6pQ&s=09",
      "githubProfile": "Palkesh-Soni-1",
      "photo": "https://drive.google.com/open?id=1SuhDW9UlcZAqG13GBiPPtiW6uciknveJ"
   },
   {
      "timestamp": "6/16/2023 16:06:18",
      "emailAddress": "kpallavi858@gmail.com",
      "name": "Pallavi",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/pallavi-641a18279",
      "instagramProfile": "https://instagram.com/_.pallavi_1122?igshid=NGExMml2YTkyZg==",
      "twitterProfile": "https://twitter.com/Pallavi_2022?s=09",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1JEgN-Db21ljeCc1m4Cg4_zvgiFiFSBM3"
   },
   {
      "timestamp": "6/16/2023 16:52:32",
      "emailAddress": "pvsm5597@gmail.com",
      "name": "Pvsm chandra reddy",
      "year": "3rd",
      "vertical": "Head - Team Management",
      "linkedinProfile": "https://www.linkedin.com/in/pvsmcr97",
      "instagramProfile": "https://instagram.com/loyal_royal_97?igshid=MzRlODBiNWFlZA==",
      "twitterProfile": "https://twitter.com/Pvsm5597?t=A58pIr-IeJDXhVdASUSWxw&s=08",
      "githubProfile": "https://github.com/Pvsm-chandra-reddy/Pvsm-chandra-reddy",
      "photo": "https://drive.google.com/open?id=1oIYsK37Ymn-gZvzXQZrSYPA63gns4iki"
   },
   {
      "timestamp": "6/16/2023 0:08:47",
      "emailAddress": "rudrapriyasharma4@gmail.com",
      "name": "Rudrapriya Sharma ",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/rudrapriya-sharma-753088276",
      "instagramProfile": "https://instagram.com/priyarudra_1602_?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1EADRSfiNxo0or60pTN7h4Mcya4lHCZ9_"
   },
   {
      "timestamp": "6/16/2023 21:15:22",
      "emailAddress": "paliwalsamanvaya07@gmail.com",
      "name": "Samanvaya Paliwal ",
      "year": "3rd",
      "vertical": "Head - Logistics and Operations",
      "linkedinProfile": "https://www.linkedin.com/in/samanvaya-paliwal-89a583153",
      "instagramProfile": "https://instagram.com/paliwal_samanvaya?igshid=NGExMmI2YTkyZg==",
      "twitterProfile": "https://twitter.com/Samanvaya2704?t=nJpc8rSFSlrzLCouOW1p_g&s=08",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1xvMXrwmb8YIcDSS91eNjrQZ5VohuHycG"
   },
   {
      "timestamp": "6/15/2023 23:39:55",
      "emailAddress": "sameekshaprusty@gmail.com",
      "name": "Sameeksha Prusty ",
      "year": "3rd",
      "vertical": "Head - General Affairs",
      "linkedinProfile": "https://www.linkedin.com/in/sameeksha-prusty-140336257",
      "instagramProfile": "https://instagram.com/sameekshaprusty?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "https://twitter.com/Sameeksha167016?t=omiGMIlAPVebDYFvGTO1Ww&s=09",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1OxyPR0HuDWyq3A1g47qgsQ7f1yv1gWH5"
   },
   {
      "timestamp": "6/16/2023 17:50:18",
      "emailAddress": "sinsanchita04@gmail.com",
      "name": "Sanchita Singh ",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/sanchita-singh-188453259",
      "instagramProfile": "https://instagram.com/__sanchitasingh03?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=14aqRc6fy6ql-8Ap2XLkC2MlGq7Mn9xrx"
   },
   {
      "timestamp": "6/16/2023 15:59:34",
      "emailAddress": "sanjanaparul940@gmail.com",
      "name": "Sanjana Kumari ",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/sanjana-kumari-3bab99258",
      "instagramProfile": "",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1G1CANDmN6HHGZbRbpWANcrbaBJDTB-Z-"
   },
   {
      "timestamp": "6/15/2023 23:08:44",
      "emailAddress": "shivanshushukla1709@gmail.com",
      "name": "Shivanshu Shukla ",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "",
      "instagramProfile": "",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": ""
   },
   {
      "timestamp": "6/16/2023 16:34:10",
      "emailAddress": "anilsinghbelwa@gmail.com",
      "name": "Shreyansh Singh",
      "year": "3rd",
      "vertical": "Head - Sponsorship and Marketing",
      "linkedinProfile": "https://www.linkedin.com/in/shreyansh-singh-279003257",
      "instagramProfile": "https://www.instagram.com/shreyansh124345/",
      "twitterProfile": "https://twitter.com/ShreyanshS50756?t=E6j9_1pA2IHQC17fnebB6g&s=08",
      "githubProfile": "https://github.com/pikachusensei",
      "photo": "https://drive.google.com/open?id=1QI5tAOtTUVTjmWzHnOSI-xTBAA7daT-0"
   },
   {
      "timestamp": "6/16/2023 16:34:10",
      "emailAddress": "rahulprajapat2@gmail.com",
      "name": "Rahul Prajapat",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/rprajapat323",
      "instagramProfile": "https://www.instagram.com/_rahul_prajapat2?igsh=aHJ2bnBwbjUxbzlk",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1R76ErGLfAWknY98jYWorJ6Zw518wd9W1"
   },
   {
      "timestamp": "6/16/2023 19:55:25",
      "emailAddress": "manojsaxena26372@gmail.com",
      "name": "Shubhi saxena ",
      "year": "3rd",
      "vertical": "Team Member",
      "instagramProfile": "https://instagram.com/itz_shubhi102?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=14QELPNoZS_MF1yQEaT5JsQB3CzH5M79I"
   },
   {
      "timestamp": "6/17/2023 20:12:51",
      "emailAddress": "tanishkrathod999@gmail.com",
      "name": "Tanishk Rathod ",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/tanishk-rathod-a08b75260",
      "instagramProfile": "https://instagram.com/tanishk7973?igshid=NGExMmI2YTkyZg==",
      "twitterProfile": "",
      "githubProfile": "https://github.com/tanishk-rathod",
      "photo": "https://drive.google.com/open?id=1R74fRy4jd8DS9li_qamXq9lfHTaCDv7K"
   },
   {
      "timestamp": "6/16/2023 19:43:11",
      "emailAddress": "tanusha.shri31@gmail.com",
      "name": "Tanusha Sharma ",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/tanusha-sharma-30631525a",
      "instagramProfile": "https://instagram.com/tanusha_sharma31?igshid=ZDc4ODBmNjlmNQ==",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=10gabOSHThQJK61HA6ykeBgEFOAagH5Nj"
   },
   {
      "timestamp": "6/17/2023 20:39:19",
      "emailAddress": "vg848969@gmail.com",
      "name": "Vaibhav Gupta",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/vaibhav-gupta-5ab50a263",
      "instagramProfile": "https://instagram.com/_im.vaibhav?igshid=ZDc4ODBmNjlmNQ==",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1iKBp49B-2SAHTCK5EiIcvTrbLZnDNjWX"
   },
   {
      "timestamp": "6/16/2023 16:24:28",
      "emailAddress": "maxsinghparihar@gmail.com",
      "name": "Vaidik singh parihar ",
      "year": "3rd",
      "vertical": "Head - Public Relations",
      "linkedinProfile": "https://www.linkedin.com/in/vaidik-singh-parihar-4aa09b250/",
      "instagramProfile": "https://www.instagram.com/aviiiiparihar/",
      "twitterProfile": "",
      "githubProfile": "https://github.com/aviiiiparihar",
      "photo": "https://drive.google.com/open?id=1OkmboGHzdbt8f6fozJoK4BTj7oeIrUDZ"
   },
   {
      "timestamp": "6/16/2023 16:45:51",
      "emailAddress": "vaishnavi293yadav@gmail.com",
      "name": "Vaishnavi Yadav ",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/vaishnavi-yadav-48a757271https://twitter.com/VaishnaviY20100?t=CRwkaks7UWDF-8bwx9mFnQ&s=08",
      "instagramProfile": "https://instagram.com/vaiisshnavi?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "https://twitter.com/VaishnaviY20100?t=CRwkaks7UWDF-8bwx9mFnQ&s=08",
      "githubProfile": "https://github.com/vaishnavi-yadav1",
      "photo": "https://drive.google.com/open?id=1cPttfp1Ui14Tz0I5G43b8colLK9cgzoS"
   },
   {
      "timestamp": "6/15/2023 23:21:39",
      "emailAddress": "vanshikaagarwal025@gmail.com",
      "name": "Vanshika Agarwal ",
      "year": "3rd",
      "vertical": "Students Jt. General Secretary(Events)",
      "linkedinProfile": "www.linkedin.com/in/vanshika-agarwal-60489b259",
      "instagramProfile": "https://instagram.com/_agarwal_vanshika?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "https://twitter.com/VanshikaAg2509",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1eiLiORQ58FroYjO2UWuU5tHenZHN84f9"
   },
   {
      "timestamp": "6/17/2023 20:32:36",
      "emailAddress": "pvanshikasingh7@gmail.com",
      "name": "Vanshika singh",
      "year": "3rd",
      "vertical": "Head - Public Relations",
      "linkedinProfile": "https://www.linkedin.com/in/vanshika-singh-114228276",
      "instagramProfile": "https://instagram.com/vanshikasingh1208?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "nil",
      "githubProfile": "nil",
      "photo": "https://drive.google.com/open?id=1eqsI4jI2Ew12aLjHAZhV9g70_NarlQ_S"
   },
   {
      "timestamp": "6/16/2023 17:20:56",
      "emailAddress": "varshakundarapu26@gmail.com",
      "name": "Varsha Kundarapu ",
      "year": "3rd",
      "vertical": "Team Member",
      "linkedinProfile": "https://www.linkedin.com/in/varsha-kundarapu",
      "instagramProfile": "https://instagram.com/varsha_kundarapu?igshid=MzRlODBiNWFlZA==",
      "twitterProfile": "https://twitter.com/varsha_k26?t=Lp2ln0XnB6cvzipJcLbzvA&s=08",
      "githubProfile": "https://github.com/Varsha2635",
      "photo": "https://drive.google.com/open?id=17E2loMkYaoTR4ezDc0yTAALCN8oIjM33"
   },
   {
      "timestamp": "6/16/2023 15:13:24",
      "emailAddress": "vibhasapretwar.work@gmail.com",
      "name": "Vibhas Apretwar",
      "year": "3rd",
      "vertical": "Head - Multimedia",
      "linkedinProfile": "https://www.linkedin.com/in/vibhas-apretwar-5b7253277",
      "instagramProfile": "https://instagram.com/vibhasapretwar?igshid=MzNlNGNkZWQ4Mg==",
      "twitterProfile": "",
      "githubProfile": "",
      "photo": "https://drive.google.com/open?id=1gT159H1KgDfZUB1BaK-deNep0seZIRL8"
   },
   {
    "timestamp": "2022-11-14T08:34:22.229Z",
    "name": "Aaryansh Pathak",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/aaryansh-pathak-424786241",
    "instagramProfile": "https://www.instagram.com/invites/contact/?i=dryk86n0rpd7&utm_content=10i9v6w",
    "photo": "https://drive.google.com/open?id=1eDAfhchaJu1ri3WupNOW3f68Ljlkh9aB"
   },
   {
    "timestamp": "2022-11-14T09:36:03.360Z",
    "name": "Akhilendra Pratap",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/akhilendrapratap",
    "instagramProfile": "https://www.instagram.com/akhilendra_11/",
    "twitterProfile": "https://twitter.com/Akhilendra112",
    "githubProfile": "https://github.com/Akhilendra01",
    "photo": "https://drive.google.com/open?id=1AkO-b4Tw3qLmHqgHe3sU_RBTZLq3OLhs"
   },
   {
    "timestamp": "2022-11-13T14:39:26.439Z",
    "name": "Akshat Maheshwari",
    "year": "4th",
    "vertical": "Students' Chairperson",
    "linkedinProfile": "https://www.linkedin.com/in/akshat-maheshwari-b5846b222",
    "instagramProfile": "https://instagram.com/akrules03?igshid=YmMyMTA2M2Y=",
    "facebookProfile": "https://www.facebook.com/profile.php?id=100083009631092",
    "twitterProfile": "https://twitter.com/Akrules03?s=20&t=WH0JqQnvrEnNd3MI1Dbp2Q",
    "githubProfile": "github.com/akrules03",
    "photo": "https://drive.google.com/open?id=1ehSxZt6zBYEfmZYNS8lD-ja4zUZunWWj"
   },
   {
    "timestamp": "2022-11-13T14:09:16.582Z",
    "name": "Akshit Sharma",
    "year": "4th",
    "vertical": "Students' Convenor",
    "linkedinProfile": "linkedin.com/in/akshit-sharma-b70610223",
    "instagramProfile": "https://www.instagram.com/akshittsharmaa/",
    "twitterProfile": "https://twitter.com/AkshittSharmaa",
    "photo": "https://drive.google.com/open?id=1jk74pggA_EurkTRbntSrEJFtJBzSg-iX"
   },
   {
    "timestamp": "2022-11-13T17:58:54.923Z",
    "name": "Aniket Mishra",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/aniket-mishra-721370129",
    "photo": "https://drive.google.com/open?id=1O3NUi7wcNsdrosiDZdh2ID7TKIkYnWvz"
   },
   {
    "timestamp": "2022-11-13T10:54:30.168Z",
    "name": "Anjaneya Dwivedi",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/anjaneya-dwivedi-0b755a201",
    "photo": require("../assets/team_img/Team__Download/anjaney.jpeg")
   },
   {
    "timestamp": "2022-11-13T15:45:53.727Z",
    "name": "Anmol Singh",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "http://linkedin.com/in/anmol-singh-b094b3244",
    "instagramProfile": "https://instagram.com/anmol12_singh?igshid=YmMyMTA2M2Y=",
    "facebookProfile": "https://www.facebook.com/profile.php?id=100076607132656",
    "photo": "https://drive.google.com/open?id=1wtMbguaiKrTYXTvdSqQWLPO0tUS49W4h"
   },
   {
    "timestamp": "2022-11-13T11:03:22.476Z",
    "name": "Anshika Arjariya ",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/anshika-arjariya-18a911244",
    "instagramProfile": "anshikaarjariya ",
    "photo": "https://drive.google.com/open?id=14oO3CclkMWWNBuhyjwbJGKw3Gcm1pL_C"
   },
   {
    "timestamp": "2022-11-13T16:34:25.128Z",
    "name": "Anurag Soni",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "Anurag Soni",
    "instagramProfile": "anuragsoni.as",
    "photo": require("../assets/team_img/Team__Download/anurag.jpeg")
   },
   {
    "name": "Gautam Pratap",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/gautampratap",
    "instagramProfile": "https://www.instagram.com/yoursgautampratap/",
    "photo": require("../assets/team_img/Team__Download/gautum.jpg")
   },
   {
    "timestamp": "2022-11-13T18:19:21.830Z",
    "name": "Arin Bansal",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.google.com/url?sa=t&source=web&rct=j&url=https://in.linkedin.com/in/arin-bansal-46109a227&ved=2ahUKEwjxyNCB4qv7AhVw7TgGHTQdCnEQjjh6BAgUEAE&usg=AOvVaw3QKYbeCVAnvYA9rNuIP4in",
    "instagramProfile": "https://www.google.com/url?sa=t&source=web&rct=j&url=https://www.instagram.com/arin_bansal/%3Fhl%3Den&ved=2ahUKEwij25mW4qv7AhW9xTgGHXJZC8QQFnoECBYQAQ&usg=AOvVaw2Sv8ANIjxM9RjZoLlLIaZu",
    "photo": "https://drive.google.com/open?id=1xlhhNeVKQStKtXoF5YLR0gnNdAG2N888"
   },
   {
    "timestamp": "2022-11-13T14:25:21.297Z",
    "name": "Arnika Chaturvedi",
    "year": "4th",
    "vertical": "Students' Co-Convenor",
    "linkedinProfile": "https://www.linkedin.com/in/arnika-chaturvedi-03444a237",
    "instagramProfile": "https://instagram.com/arnika_chaturvedi?igshid=YmMyMTA2M2Y=",
    "photo": "https://drive.google.com/open?id=1vKh7ZfK8V1l0h4W8P0bkauESlaFtVgFs"
   },
   {
    "timestamp": "2022-11-14T09:37:31.675Z",
    "name": "Arpit Gupta",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/arpit-gupta-24170b238",
    "instagramProfile": "https://instagram.com/_.arpit_.01?igshid=YmMyMTA2M2Y=",
    "twitterProfile": "https://twitter.com/ArpitGu72813231?t=-cS7mVb-Mlj78fK2e9zrYw&s=09",
    "photo": "https://drive.google.com/open?id=1jQF59iYZ1rFPr-GKfKwzl-2ZnzRs3FCA"
   },
   {
    "timestamp": "2022-11-13T17:32:14.725Z",
    "name": "Ashay Jain ",
    "year": "4th",
    "vertical": "Core Team Member ",
    "linkedinProfile": "https://www.linkedin.com/in/ashay-jain-485a43244",
    "instagramProfile": "easyyy_10",
    "photo": require("../assets/team_img/ashay.jpg")
   },
   {
    "timestamp": "2022-11-13T14:24:02.901Z",
    "name": "Asmita Jain",
    "year": "4th",
    "vertical": "Students Co-Facilitator",
    "linkedinProfile": "https://www.linkedin.com/in/asmita-jain-a20343239",
    "instagramProfile": "https://instagram.com/asmita_jain03?igshid=YmMyMTA2M2Y=",
    "photo": "https://drive.google.com/open?id=1j2Vat-0jg0bqtPKngGZVLiVM6RowQPKQ"
   },
   {
    "timestamp": "2022-11-13T12:05:46.160Z",
    "name": "Ayushi Verma ",
    "year": "4th",
    "vertical": "Core Team Member ",
    "linkedinProfile": "https://www.linkedin.com/in/ayushi-verma-318a10244",
    "instagramProfile": "https://instagram.com/ayushiii_v?igshid=YmMyMTA2M2Y=",
    "facebookProfile": "https://www.facebook.com/profile.php?id=100083007799113",
    "photo": "https://drive.google.com/open?id=1OGLaVQ7APqUmiZxW8t-r55d_wvHxMZrX"
   },
   {
    "timestamp": "2022-11-13T14:05:55.377Z",
    "name": "Bharat Verma ",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/bharat-verma-385284243",
    "instagramProfile": "https://instagram.com/cheeee_ku?igshid=YmMyMTA2M2Y=",
    "facebookProfile": "https://www.facebook.com/profile.php?id=100032990748860",
    "photo": "https://drive.google.com/open?id=1u8lIBRRZBr5d689n92Y5KcAvHIkoUTTg"
   },
   {
    "timestamp": "2022-11-13T10:36:43.379Z",
    "name": "Bhumika Chouhan",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/bhumika-chouhan-237906237",
    "photo": "https://drive.google.com/open?id=1avHA3CHkP0I7mkMkLwNaystx_s41aYY1"
   },
   {
    "timestamp": "2022-11-13T14:24:19.693Z",
    "name": "Disha Badetia",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/disha-badetia-337aa3242/",
    "instagramProfile": "https://www.instagram.com/disha.1574/",
    "photo": "https://drive.google.com/open?id=1QkEsrtI8-dbCTORr8JcgPm4IjVFRbRug"
   },
   {
    "timestamp": "2022-11-14T10:39:04.008Z",
    "name": "Jayant Dhakad",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/jayant-dhakad-8a5517233/",
    "instagramProfile": "https://www.instagram.com/jayant_dhakad.1/",
    "githubProfile": "https://github.com/jake8989",
    "photo": "https://drive.google.com/open?id=1zl6VlaMgD7QmY5GNiiJEQL7qJq8LjrQM"
   },
   {
    "timestamp": "2022-11-13T10:00:11.868Z",
    "name": "Kartik Tiwari",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/kartik-tiwari-954b54244",
    "githubProfile": "Kartik79",
    "photo": "https://drive.google.com/open?id=1lqUcOANVgHQIPPIdnIVakWWQ_dt9DAZl"
   },
   {
    "timestamp": "2022-11-13T10:49:22.120Z",
    "name": "Kartikeya Mande",
    "year": "4th",
    "vertical": "Treasurer",
    "linkedinProfile": "https://www.linkedin.com/in/kartikeya-mande-a5a006225",
    "instagramProfile": "https://instagram.com/kartikeyamande19?igshid=YmMyMTA2M2Y=",
    "facebookProfile": "https://www.facebook.com/kartikeya.mande",
    "githubProfile": "https://github.com/Kartikeya192002",
    "photo": require("../assets/team_img/Team__Download/mande.jpg")
   },
   {
    "timestamp": "2022-11-13T18:05:24.580Z",
    "name": "Kushagra Patel",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/kushagra-patel-43185b22b",
    "instagramProfile": "kushagra_amicable",
    "facebookProfile": "https://www.facebook.com/profile.php?id=100086292642370",
    "twitterProfile": "None",
    "githubProfile": "None",
    "photo": "https://drive.google.com/open?id=1_h7TLeTUYgMmb8r7nAVS8KMd-b8CIlUl"
   },
   {
    "timestamp": "2022-11-13T16:42:29.181Z",
    "name": "Lokesh Pawar",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/wholokeshpawar/",
    "instagramProfile": "https://www.instagram.com/lokesh.pawar.5/",
    "facebookProfile": "https://www.facebook.com/lokesh.pawar.9484941/",
    "twitterProfile": "https://mobile.twitter.com/lokeshpawar51",
    "photo": "https://drive.google.com/open?id=15WFPyElcyBt--uB5OJLhNQJKzQf4RSj9"
   },
   {
    "timestamp": "2022-11-13T17:58:57.765Z",
    "name": "Muzaina Tauqeer",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/muzaina-tauqeer-5a263b245",
    "instagramProfile": "muzaina_tauqeer",
    "facebookProfile": "https://www.facebook.com/profile.php?id=100080462827438",
    "twitterProfile": "-",
    "githubProfile": "-",
    "photo": "https://drive.google.com/open?id=170826QDTGunpbxDICv7AWkWO3ZF0OvjB"
   },
   {
    "timestamp": "2022-11-13T09:22:32.338Z",
    "name": "Navneethan Pillai ",
    "year": "4th",
    "vertical": "Students' Vice-Chairperson(Events)",
    "linkedinProfile": "https://www.linkedin.com/in/navneethan-pillai-ab9b53229",
    "instagramProfile": "_._navneethan_.___",
    "facebookProfile": "https://www.facebook.com/navneethan.pillai",
    "twitterProfile": "https://twitter.com/Navneethan_5320?t=pvKTn0cZ6MjT2SuHXbXhzQ&s=08",
    "photo": "https://drive.google.com/open?id=1hmPrJ6dBIgX_ZrcX_nSnsfXXEHodi1sN"
   },
   {
    "timestamp": "2022-11-13T11:26:03.238Z",
    "name": "Rajkumar Majhi ",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/rajkumar-majhi-1278b5226",
    "instagramProfile": "https://www.instagram.com/art_by_rajkumar/",
    "facebookProfile": "https://m.facebook.com/rajkumar.majhi.5473894?eav=AfbphBhB888-yGDiqJl4t4-QfpENKHJKT0WY0Xmr9X35cYVqp6U_BnfL3GZAbsSHodU&ref_component=mbasic_home_header&ref_page=%2Fwap%2Fhome.php&refid=8&paipv=0",
    "twitterProfile": "https://twitter.com/Rajkumar__majhi?t=gL5nThuKTIRCqGlmiJC2Rg&s=09",
    "githubProfile": "https://github.com/rajkumarmajhi31",
    "photo": "https://drive.google.com/open?id=18gq2S_oNTloJ_d_ma0scDRviKkqvC56b"
   },
   {
    "timestamp": "2022-11-14T04:58:21.282Z",
    "name": "Rama Bhala",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/rama-bhala-84143a224",
    "photo": "https://drive.google.com/open?id=1ArN_JxLgjoXR3giHCxpLWs65VRcaGdOJ"
   },
   {
    "timestamp": "2022-11-13T09:48:04.673Z",
    "name": "Rudraksh",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/rudraksh-rajput-60249b244",
    "instagramProfile": "rudr_aksh2002",
    "photo": "https://drive.google.com/open?id=1Uocxd82St3FVYWyHfmFgU1pYgr_9UhsW"
   },
   {
    "timestamp": "2022-11-13T16:43:13.570Z",
    "name": "Saiyam Saxena",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/saiyam-saxena-b28961241/",
    "instagramProfile": "https://www.instagram.com/saiyam_saxena/",
    "facebookProfile": "https://www.facebook.com/profile.php?id=100007095016924",
    "twitterProfile": "https://twitter.com/SaiyamSaxena",
    "githubProfile": "https://github.com/Saiyam10",
    "photo": "https://drive.google.com/open?id=1kn0xPtbxFJYUgdUuu-Q6yKQBnIV0oQSz"
   },
   {
    "timestamp": "2022-11-13T14:33:41.046Z",
    "name": "Samyukta alawa",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/samyukta-alawa-1785ba245",
    "instagramProfile": " _samyukta_25",
    "facebookProfile": "-",
    "twitterProfile": "-",
    "githubProfile": "-",
    "photo": require("../assets/team_img/Team__Download/samyukta.jpeg")
   },
   {
    "timestamp": "2022-11-13T11:00:57.770Z",
    "name": "Sania Zehra Rizvi ",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/sania-zehra-rizvi-6b7a95244",
    "instagramProfile": "https://instagram.com/_.sa.n.ia._?igshid=YmMyMTA2M2Y=",
    "photo": "https://drive.google.com/open?id=1hSJJZ6_RtbesLJrhIUaMSkH1ZNXFWt_J"
   },
   {
    "timestamp": "2022-11-14T08:33:38.058Z",
    "name": "Sanidhya Vijay",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/sanidhya-vijay-02b183229",
    "instagramProfile": "https://www.instagram.com/invites/contact/?i=artry4wm730f&utm_content=22347mn",
    "photo": "https://drive.google.com/open?id=1jSh43dQuRIUfE1sKW7pyqHmRd9zG9mwL"
   },
   {
    "timestamp": "2022-11-14T04:55:14.755Z",
    "name": "Sanya Arora ",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/sanya-arora-228212241",
    "photo": "https://drive.google.com/open?id=1LjxSVshH382ac28eF2N3mYuaoQYNOXRy"
   },
   {
    "timestamp": "2022-11-13T21:02:46.815Z",
    "name": "Shailee Gavnekar ",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/shailee-gavnekar-32b780233",
    "instagramProfile": "https://instagram.com/shailee_gavnekar27?igshid=YmMyMTA2M2Y=",
    "facebookProfile": "https://www.facebook.com/profile.php?id=100083231649592",
    "twitterProfile": "https://twitter.com/GavnekarShailee?t=SOxijlZ8BsR6knG3eFU3Dg&s=08",
    "photo": "https://drive.google.com/open?id=1ci1KvIxcPNDnEMQ5TrFgShqC1spv1BAl"
   },
   {
    "timestamp": "2022-11-13T12:09:26.871Z",
    "name": "Suhaani Batra",
    "year": "4th",
    "vertical": "Students' Vice-Chairperson (Administration)",
    "linkedinProfile": "https://www.linkedin.com/in/suhaani-batra-842858244/",
    "instagramProfile": "https://www.instagram.com/suhaanii.batra/",
    "facebookProfile": "https://www.facebook.com/profile.php?id=100082825976960",
    "twitterProfile": "https://twitter.com/BatraSuhaani",
    "githubProfile": "https://github.com/suhaanibatra",
    "photo": "https://drive.google.com/open?id=1wHxB1YsWDL44seU_tKCWsCrkoBkbi7BV"
   },
   {
    "timestamp": "2022-11-13T14:14:38.134Z",
    "name": "Suhani Jain ",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/suhani-jain-b96800239",
    "instagramProfile": "https://instagram.com/accio_oo9?igshid=YmMyMTA2M2Y=",
    "facebookProfile": "https://www.facebook.com/profile.php?id=100082908894329",
    "twitterProfile": "https://twitter.com/Accio5406?t=WpN-jTH8WSjeOILYm_ud-Q&s=09",
    "photo": "https://drive.google.com/open?id=12W2ESEJgY90xWVdwX7UpxaR7rCwJi9DY"
   },
   {
    "timestamp": "2022-11-13T09:31:02.305Z",
    "name": "Suyash Tiwari ",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/suyash-tiwari-8b1910244",
    "instagramProfile": "suyash09tiwari",
    "facebookProfile": "Suyash Tiwari ",
    "photo": "https://drive.google.com/open?id=188R4GISphXtnqN3LxjJfvNoErHswYMyw"
   },
   {
    "timestamp": "2022-11-13T14:32:21.947Z",
    "name": "Uday Pratap Singh ",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/uday-pratap-singh-857869229",
    "instagramProfile": "the_bfg__",
    "facebookProfile": "https://www.facebook.com/profile.php?id=100083295217122",
    "photo": "https://drive.google.com/open?id=13iHvYY7zFcZfCjjNFanYj5JPWRAIRtas"
   },
   {
    "timestamp": "2022-11-13T22:46:27.344Z",
    "name": "Vidit Purohit ",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/vidit-purohit-36b538231",
    "instagramProfile": "https://instagram.com/vidit01purohit?igshid=YmMyMTA2M2Y=",
    "photo": require("../assets/team_img/Team__Download/vidit.jpeg")
   },
   {
    "timestamp": "2022-11-13T10:17:01.027Z",
    "name": "Zoheb Hasan ",
    "year": "4th",
    "vertical": "Core Team Member",
    "linkedinProfile": "https://www.linkedin.com/in/zoheb-hasan-74a35a231",
    "facebookProfile": "https://www.facebook.com/zoheb.hasan.106",
    "photo": "https://drive.google.com/open?id=11RI24AF463XQ5SawsacXnvwHjQyS_X8s"
   }
];

export default data;

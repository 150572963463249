import React from 'react';
import notF from '../../assets/Backgrounds/404.jpeg';
// import Nav1 from './Nav1';
const NotFound = () => {
	return (
		<div>
			{/* <h1>Not Found</h1>
			 */}
			<div>
				<p></p>
				<div>
					<img src={notF} alt="" />
				</div>
			</div>
		</div>
	);
};

export default NotFound;
